import { useRef, FC, MutableRefObject } from 'react';

import MagritteWrapper from 'src/components/MagritteWrapper/MagritteWrapper';
import NovaFilterUpdateContextProvider from 'src/components/NovaFilters/NovaFilterUpdateContext';

import FiltersForm from 'src/components/Employer/VacancySearch/FiltersForm';
import SearchContent from 'src/components/Employer/VacancySearch/SearchContent';
import useExpEmployerVacancySearch from 'src/components/Employer/VacancySearch/useExpEmployerVacancySearch';
import useSendUxFeedbackOnVacancyList from 'src/components/Employer/VacancySearch/useSendUxFeedbackOnVacancyList';

const VacancySearchWidget: FC = () => {
    const widgetRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
    const { elementSpyRef: uxFeedbackElement } = useSendUxFeedbackOnVacancyList();
    const scrollWidgetTop = () => {
        widgetRef.current && widgetRef.current.scrollIntoView();
    };
    const { elementSpyRef: experimentStatusElement } = useExpEmployerVacancySearch();

    return (
        <MagritteWrapper isEnabled>
            <NovaFilterUpdateContextProvider>
                <div
                    ref={(element) => {
                        [widgetRef, uxFeedbackElement, experimentStatusElement].forEach(
                            (item) => (item.current = element)
                        );
                    }}
                >
                    <FiltersForm />
                    <SearchContent scrollWidgetTop={scrollWidgetTop} />
                </div>
            </NovaFilterUpdateContextProvider>
        </MagritteWrapper>
    );
};

export default VacancySearchWidget;
