import { FC } from 'react';

import VacancySearch from 'src/components/Employer/VacancySearch';
import { useSelector } from 'src/hooks/useSelector';

import VacanciesBlockOld from 'src/components/Employer/VacanciesGroupsByRegion/VacanciesGroupsByRegion';

const VacanciesGroups: FC = () => {
    const showEmployerVacancySearch = useSelector(({ showEmployerVacancySearch }) => showEmployerVacancySearch);

    return showEmployerVacancySearch ? <VacancySearch /> : <VacanciesBlockOld />;
};

export default VacanciesGroups;
