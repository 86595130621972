import Analytics from '@hh.ru/analytics-js';
import { Button } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { getTemplateCriteria } from 'src/models/employerVacancyTemplateFilter/getTemplateCriteria';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';

const TrlKeys = {
    buttonText: 'employer.view.chameleon.vacancies.filtered.link',
};

const VacanciesLink: TranslatedComponent<{ isWebview?: boolean }> = ({ trls, isWebview }) => {
    const employerId = useSelector(({ employerInfo }) => employerInfo.id);
    const templateFilter = useSelector((state) => state.employerVacancyTemplateFilter);
    const topLevelDomain = useSelector((state) => state.topLevelDomain);
    const isLoading = useSelector(
        (state) => state.employerVacancySearch.isLoading && (state.showEmployerVacancySearch || isWebview)
    );
    const hiddenFilters = getTemplateCriteria(templateFilter);

    const pathName = `/employer/${String(employerId)}/vacancies/`;
    const url = urlParser(isWebview ? `https://${topLevelDomain}${pathName}` : pathName);

    url.params = {
        ...templateFilter,
        [CriteriaKey.CurrentEmployerId]: isWebview ? Number(employerId) : undefined,
        [CriteriaKey.HiddenFilters]: hiddenFilters,
    };

    return (
        <Button
            Element={Link}
            to={url.href}
            target={isWebview ? '_blank' : '_self'}
            size="medium"
            style="neutral"
            loading={isLoading}
            mode="secondary"
            additionalQueryParams={{
                hhtmFromLabel: 'vacancies_in_selected_section_link',
            }}
            stretched
            data-qa="employer-page-tabs-xs-go-filtered-vacancies"
            onClick={() => {
                Analytics.sendHHEventButtonClick('vacancies_in_current_department_button_click', {
                    employerId,
                    isWebview,
                });
            }}
        >
            {trls[TrlKeys.buttonText]}
        </Button>
    );
};

export default translation(VacanciesLink);
