import { FC, memo } from 'react';

import styles from './skeleton.less';

interface SkeletonStripeProps {
    width?: number;
}

const SkeletonStripe: FC<SkeletonStripeProps> = ({ width }) => <div className={styles.stripe} style={{ width }} />;

export default memo(SkeletonStripe);
