import { ElementShownAnchor } from '@hh.ru/analytics-js';
import employerAutosearchElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/employer_page/employer_autosearch_element_shown';
import { Button, ButtonSize } from '@hh.ru/magritte-ui';
import { LangTrls, TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SubscribeButton from 'src/components/Employer/Subscribe';
import { useSubscribeContext } from 'src/components/Employer/Subscribe/SubscribeContext';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    employerViewVacanciesSubscribe: 'employer.view.sidebar.button.vacanciesSubscribe',
    success: 'save-search-employer-autosearch-link',
};

interface SubscribeProps {
    stretched?: boolean;
    subscribeButtonText?: string;
    size?: ButtonSize;
}

const getButtonText = (count: number | undefined, trls: LangTrls, subscribeButtonText?: string): string => {
    if (count) {
        return trls[TrlKeys.success];
    }
    return subscribeButtonText || trls[TrlKeys.employerViewVacanciesSubscribe];
};

const Subscribe: TranslatedComponent<SubscribeProps> = ({ stretched, subscribeButtonText, trls, size = 'small' }) => {
    const { count, isSubscribeAllowed } = useSubscribeContext();
    const employerInfo = useSelector(({ employerInfo }) => employerInfo);
    const { id: employerId } = employerInfo;

    if (!isSubscribeAllowed) {
        return null;
    }

    return (
        <ElementShownAnchor
            fn={employerAutosearchElementShown}
            elementName={count ? 'employer_autosearch_link' : 'employer_autosearch_subscribe'}
            employerId={String(employerId)}
        >
            <Button
                Element={SubscribeButton}
                size={size}
                style="neutral"
                mode="secondary"
                stretched={stretched}
                data-qa={count ? 'vacancy-serp__search-saved' : 'employer__search-saved'}
            >
                {getButtonText(count, trls, subscribeButtonText)}
            </Button>
        </ElementShownAnchor>
    );
};

export default translation(Subscribe);
