import { MutableRefObject, useEffect, useRef } from 'react';

import { bottomVacancySearchElementShownRaw } from '@hh.ru/analytics-js-events/build/xhh/common/vacancy_search/employer_page_vacancy_search/bottom_vacancy_search_element_shown';
import { useBreakpoint } from '@hh.ru/magritte-ui';
import elementSpy, { Bound } from 'bloko/common/elementSpy';

import useExperiment from 'src/hooks/useExperiment';
import { useExperimentGroupCheckFunction } from 'src/hooks/useExperimentCheckFunction';
import { useSelector } from 'src/hooks/useSelector';

const useExpEmployerVacancySearch = (): {
    elementSpyRef: MutableRefObject<HTMLDivElement | null>;
} => {
    const elementSpyRef = useRef<HTMLDivElement>(null);
    const hasChameleon = useSelector(({ hasChameleon }) => hasChameleon);
    const { isMobile } = useBreakpoint();
    const isElementShown = !isMobile && hasChameleon;
    const vacancyCount = useSelector((state) => state.activeEmployerVacancyCount);
    const employerId = useSelector((state) => state.employerInfo.id as number);
    const isEmployerVacancySearchAllowed = useSelector((state) => state.isEmployerVacancySearchAllowed);
    useExperiment('employer_vacancy_search_widget', isEmployerVacancySearchAllowed && !isElementShown);
    const getExperimentGroup = useExperimentGroupCheckFunction();

    useEffect(() => {
        const element = elementSpyRef.current;
        if (isElementShown && isEmployerVacancySearchAllowed && element) {
            const { stopSpying } = elementSpy(element, {
                onShow() {
                    stopSpying();
                    getExperimentGroup('employer_vacancy_search_widget');
                    bottomVacancySearchElementShownRaw({ employerId, vacancyCount });
                },
                trackingBounds: [Bound.Top],
            });
        }
    }, [employerId, getExperimentGroup, isElementShown, isEmployerVacancySearchAllowed, vacancyCount]);

    return { elementSpyRef };
};

export default useExpEmployerVacancySearch;
