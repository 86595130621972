import { FC, memo, PropsWithChildren, useRef } from 'react';

import styles from './skeleton.less';

interface SkeletonLineProps {
    height?: number;
}

const MIN_WIDTH_PERCENT = 50;
const MAX_WIDTH_PERCENT = 90;
const getRandomWidth = () => MIN_WIDTH_PERCENT + Math.floor(Math.random() * (MAX_WIDTH_PERCENT - MIN_WIDTH_PERCENT));

const SkeletonLine: FC<PropsWithChildren<SkeletonLineProps>> = ({ height, children }) => {
    const widthRef = useRef(getRandomWidth());
    return (
        <div className={styles.line} style={{ height, width: `${widthRef.current}%` }}>
            {children}
        </div>
    );
};

export default memo(SkeletonLine);
