import { ComponentPropsWithoutRef, forwardRef } from 'react';

import { useSubscribeContext } from 'src/components/Employer/Subscribe/SubscribeContext';

const Subscribe = forwardRef<HTMLButtonElement, ComponentPropsWithoutRef<'button'>>(({ children, ...props }, ref) => {
    const { handleSaveClick, saveSearchSubmitDisabled } = useSubscribeContext() || {};

    return (
        <button {...props} onClick={handleSaveClick} disabled={saveSearchSubmitDisabled} ref={ref}>
            {children}
        </button>
    );
});

export default Subscribe;
