import { AppStore } from 'src/app/store';
import { CountryId } from 'src/models/countryId';
import { UserType } from 'src/models/userType';

const checkWidgetRender = (state: AppStore): boolean => {
    const isTrusted = state.employerInfo.isTrusted || state.vacancyView.company['@trusted'];
    const isRussia = state.countryId === CountryId.Russia;
    const hasBigWidgetMicrofrontend = !!state.microFrontends.employerReviewsBig;

    return isRussia && isTrusted && hasBigWidgetMicrofrontend;
};

export const checkWidgetRenderForVacancyView = (state: AppStore): boolean => {
    const userType = state.userType;
    return [UserType.Anonymous, UserType.Applicant, UserType.NedoUser].includes(userType) && checkWidgetRender(state);
};

export default checkWidgetRender;
