import { FC } from 'react';

import { usePageTabsContext } from 'src/components/Employer/PageTabs/PageTabsContext';

import TemplateFilterProvider from 'src/components/Employer/VacancySearch/TemplateFilterProvider';
import VacanciesLink from 'src/components/Employer/VacancySearch/VacanciesLink';
import VacancySearchWidget from 'src/components/Employer/VacancySearch/VacancySearchWidget';

const VacancySearch: FC<{ hasChameleon?: boolean }> = ({ hasChameleon }) => {
    const { isMobileView } = usePageTabsContext();

    if (hasChameleon) {
        return (
            <TemplateFilterProvider>
                {isMobileView ? <VacanciesLink /> : <VacancySearchWidget />}
            </TemplateFilterProvider>
        );
    }

    return <VacancySearchWidget />;
};

export default VacancySearch;
