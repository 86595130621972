import { FC } from 'react';

import { LoadingContextProvider, Cell, useBreakpoint, VSpacing, VSpacingContainer, Card } from '@hh.ru/magritte-ui';

import style from './search-content-loader.less';

const SearchContentLoader: FC = () => {
    const { isXS } = useBreakpoint();
    return (
        <LoadingContextProvider loading={true}>
            <div className={style.searchContentLoader}>
                <Cell.Skeleton height={14} width={isXS ? 86 : 138} borderRadius={4} />
                {isXS && <Cell.Skeleton height={14} width={138} borderRadius={4} />}
            </div>
            <VSpacing default={16} />
            <VSpacingContainer default={12}>
                {new Array(isXS ? 3 : 2).fill(1).map((_, i) => (
                    <Card.Skeleton key={i} borderRadius={24} height={isXS ? 448 : 282} stretched />
                ))}
            </VSpacingContainer>
        </LoadingContextProvider>
    );
};

export default SearchContentLoader;
