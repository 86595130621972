import classnames from 'classnames';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import sidebarButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/branding/employer_page/sidebar_button_click';
import sidebarElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/branding/employer_page/sidebar_element_shown';
import { HSpacingContainer, VSpacing } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import Conversion from 'bloko/blocks/conversion';
import Text from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import paths from 'src/app/routePaths';
import OpenEmployerIcon from 'src/components/Employer/ChameleonHeader/components/OpenEmployerIcon';
import EmployerHHRatingIcon from 'src/components/EmployerHHRatingIcon';
import HrBrandIcon from 'src/components/HrBrandIcon';
import translation from 'src/components/translation';
import useGetCompanyHHRatingBadge from 'src/hooks/companies/useGetCompanyHHRatingBadge';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';

interface ContentProps {
    withoutLogo?: boolean;
    colors?: Record<string, string>;
}

const TrlKeys = {
    vacanciesTitle: 'employer.sidebar.vacancies.title',
    vacanciesCountOne: 'employer.sidebar.vacancies.count.one',
    vacanciesCountSome: 'employer.sidebar.vacancies.count.some',
    vacanciesCountMany: 'employer.sidebar.vacancies.count.many',
    awards: 'employer.sidebar.awards',
    scope: 'employer.sidebar.scope',
    interview: 'employer.page.interview',
};

const Content: TranslatedComponent<ContentProps> = ({ trls, withoutLogo, colors }) => {
    const insider = useSelector((state) => state.employerInsiderInterview);
    const vacanciesCount = useSelector(
        (state) => state.vacanciesGroupsByRegion?.count ?? state.activeEmployerVacancyCount
    );
    const industries = useSelector((state) => state.employerInfo.industries);
    const address = useSelector((state) => state.employerInfo.address);
    const isTrusted = useSelector((state) => state.employerInfo.isTrusted);
    const accepted = useSelector((state) => state.employerInfo.accepted);
    const site = useSelector((state) => state.employerInfo.site);
    const badges = useSelector((state) => state.employerInfo.badges);
    const hrBrand = useSelector((state) => state.employerInfo.hrBrand);
    const employerId = useSelector((state) => state.employerInfo.id);
    const isOpenEmployer = useSelector((state) => state.employerReviewsIsOpenEmployer);
    const isZarplata = useIsZarplataPlatform();
    const headerStyle = colors?.header ? { color: `#${colors.header}` } : {};
    const textStyle = colors?.text ? { color: `#${colors.text}` } : {};
    const linkStyle = colors?.link ? { color: `#${colors.link}` } : {};

    const employerHHRatingBadge = useGetCompanyHHRatingBadge(badges);

    return (
        <div
            className={classnames('employer-sidebar-content', {
                'employer-sidebar-content_without-logo': withoutLogo,
            })}
            style={textStyle}
            data-qa="sidebar-text-color"
        >
            {address && <div className="employer-sidebar-block">{address}</div>}
            {site && accepted && (
                <ElementShownAnchor fn={sidebarElementShown} elementName="site_link">
                    <Link
                        to={site.href}
                        data-qa="sidebar-company-site"
                        onClick={() => sidebarButtonClick({ buttonName: 'site_link' })}
                    >
                        <span style={linkStyle}>{site.hostname}</span>
                    </Link>
                </ElementShownAnchor>
            )}
            {!!vacanciesCount && !isZarplata && (
                <div className="employer-sidebar-block">
                    <Text strong data-qa="sidebar-header-color" style={headerStyle}>
                        {trls[TrlKeys.vacanciesTitle]}
                    </Text>
                    <Link
                        to={`${paths.vacancySearch}?from=employerPage&employer_id=${employerId || ''}`}
                        data-qa="employer-page__employer-vacancies-link"
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore Property 'style' does not exist
                        style={linkStyle}
                        onClick={() => sidebarButtonClick({ buttonName: 'active_vacancies' })}
                    >
                        <ElementShownAnchor Element="span" fn={sidebarElementShown} elementName="active_vacancies">
                            <Conversion
                                value={vacanciesCount}
                                one={trls[TrlKeys.vacanciesCountOne]}
                                some={trls[TrlKeys.vacanciesCountSome]}
                                many={trls[TrlKeys.vacanciesCountMany]}
                                zero={trls[TrlKeys.vacanciesCountMany]}
                            />
                        </ElementShownAnchor>
                    </Link>
                </div>
            )}
            {!!industries?.length && (
                <div className="employer-sidebar-block">
                    <Text strong data-qa="sidebar-header-color" style={headerStyle}>
                        {trls[TrlKeys.scope]}
                    </Text>
                    {industries.map((industry) => {
                        return <p key={industry.id}>{industry.trl}</p>;
                    })}
                </div>
            )}
            {!!insider?.articles?.length && (
                <div className="employer-sidebar-block">
                    <Text strong data-qa="sidebar-header-color" style={headerStyle}>
                        {trls[TrlKeys.interview]}
                    </Text>
                    {insider.articles.map((article) => {
                        return (
                            <Link
                                to={`/interview/${article.code}?employerId=${insider.employerId}`}
                                className="employer-sidebar-interview-url"
                                key={article.code}
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore Property 'style' does not exist
                                style={linkStyle}
                            >
                                {article.title}
                            </Link>
                        );
                    })}
                </div>
            )}
            {(hrBrand || employerHHRatingBadge || (isOpenEmployer && isTrusted)) && (
                <div className="employer-sidebar-block">
                    <Text strong data-qa="sidebar-header-color" style={headerStyle}>
                        {trls[TrlKeys.awards]}
                    </Text>
                    <VSpacing default={4} />
                    <HSpacingContainer default={4}>
                        <OpenEmployerIcon size="small" />
                        {employerHHRatingBadge && (
                            <EmployerHHRatingIcon badge={employerHHRatingBadge} size="small" dataQaPrefix="sidebar-" />
                        )}
                        {hrBrand && <HrBrandIcon hrBrand={hrBrand} size="small" dataQa="sidebar-hr-brand-icon" />}
                    </HSpacingContainer>
                </div>
            )}
        </div>
    );
};

export default translation(Content);
