import { createContext, useContext } from 'react';

export interface SubscribeContextValue {
    count?: number;
    handleSaveClick: () => Promise<void>;
    saveSearchSubmitDisabled: boolean;
    isSubscribeAllowed: boolean;
}

const SubscribeContext = createContext<SubscribeContextValue | null>(null);

export const useSubscribeContext = (): SubscribeContextValue => {
    const value = useContext(SubscribeContext);
    if (!value) {
        throw new Error('Subscribe context requested outside of provider');
    }
    return value;
};

export default SubscribeContext;
