import { useCallback, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router';

import { usePush } from '@hh.ru/redux-spa-middleware';

import { useSetEmployerVacancyListDefault } from 'src/api/employerVacancySearch/useSetEmployerVacancies';
import { useSelector } from 'src/hooks/useSelector';

import { EmployerPageTab, isEmployerPageTab } from 'src/components/Employer/PageTabs/PageTabsContext';
import useTabUrl, { getRouteTab } from 'src/components/Employer/PageTabs/useTabUrl';

interface UseCurrentTab {
    (): { currentTab: EmployerPageTab; setCurrentTab: (newTab: string) => void; hasPreviusTab: boolean };
}

const useCurrentTab: UseCurrentTab = () => {
    const hasChameleon = useSelector((state) => state.hasChameleon);
    const [hasPreviusTab, setHasPreviusTab] = useState(false);
    const showEmployerVacancySearch = useSelector(({ showEmployerVacancySearch }) => showEmployerVacancySearch);
    const { setEmployerVacancyListDefault } = useSetEmployerVacancyListDefault();
    const match = useRouteMatch<{ 0: string; 1: string }>();
    const subRoute = match.params[1];
    const currentTab = getRouteTab(subRoute);
    const makeTabUrl = useTabUrl();
    const push = usePush();

    const setCurrentTab = useCallback(
        (newTab: string) => {
            if (!isEmployerPageTab(newTab) || newTab === currentTab) {
                return;
            }
            if (hasChameleon) {
                push(makeTabUrl(newTab));
                return;
            }
            setHasPreviusTab(true);
            push(makeTabUrl(newTab), undefined, { cancelFetchingData: true });
            showEmployerVacancySearch && newTab === EmployerPageTab.Vacancies && setEmployerVacancyListDefault();
        },
        [currentTab, push, hasChameleon, makeTabUrl, setEmployerVacancyListDefault, showEmployerVacancySearch]
    );

    return useMemo(() => ({ currentTab, setCurrentTab, hasPreviusTab }), [currentTab, hasPreviusTab, setCurrentTab]);
};

export default useCurrentTab;
