import { FC } from 'react';

import VacanciesFilter from 'src/components/Employer/VacanciesFilter';
import VacanciesGroupsByRegion from 'src/components/Employer/VacanciesGroupsByRegion';
import VacancySearch from 'src/components/Employer/VacancySearch';
import { useSelector } from 'src/hooks/useSelector';

import { usePageTabsContext, EmployerPageTab } from 'src/components/Employer/PageTabs/PageTabsContext';

const PageTabsMobileOverlayContent: FC = () => {
    const { currentTab } = usePageTabsContext();
    const isShowEmployerVacancySearch = useSelector(({ showEmployerVacancySearch }) => showEmployerVacancySearch);
    const hasChameleon = useSelector((state) => state.hasChameleon);

    if (currentTab !== EmployerPageTab.Vacancies) {
        return null;
    }

    if (hasChameleon) {
        return isShowEmployerVacancySearch ? <VacancySearch /> : <VacanciesFilter />;
    }

    return <VacanciesGroupsByRegion />;
};

export default PageTabsMobileOverlayContent;
