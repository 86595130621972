import { Placeholder, Button, Text, useBreakpoint } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Subscribe from 'src/components/Employer/Subscribe';
import { useSubscribeContext } from 'src/components/Employer/Subscribe/SubscribeContext';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const PLACEHOLDER_IMAGE = '/images/hh/employer/vacancySearch/not-found.svg';

enum EmptySearchResult {
    NoActiveVacancies = 'noActiveVacancies',
    NotFound = 'notFound',
}

const TrlKeys = {
    [EmptySearchResult.NoActiveVacancies]: {
        title: 'employer.vacancySearch.placeholder.noActiveVacancies.title',
        description: 'employer.vacancySearch.placeholder.noActiveVacancies.description',
    },
    [EmptySearchResult.NotFound]: {
        title: 'employer.vacancySearch.placeholder.notFound.title',
        description: 'employer.vacancySearch.placeholder.notFound.description',
    },
    buttonText: {
        noActiveVacanciesDefault: 'employer.vacancySearch.placeholder.noActiveVacancies.buttonText',
        noActiveVacanciesSucces: 'save-search-employer-autosearch-link',
        notFound: 'employer.vacancySearch.placeholder.notFound.buttonText',
    },
};

interface NoVacancies {
    type?: EmptySearchResult;
}

const NoVacancies: TranslatedComponent<NoVacancies> = ({ trls }) => {
    const type = useSelector(({ activeEmployerVacancyCount }) =>
        activeEmployerVacancyCount ? EmptySearchResult.NotFound : EmptySearchResult.NoActiveVacancies
    );
    const staticHost = useSelector(({ config }) => config.staticHost);
    const { isSubscribeAllowed, count } = useSubscribeContext();
    const isSubscribeButtonShowed = type === EmptySearchResult.NoActiveVacancies && isSubscribeAllowed;
    const { isMobile } = useBreakpoint();
    const { title, description } = TrlKeys[type];

    return (
        <Placeholder
            maxWidth={650}
            description={
                <Text typography={isMobile ? 'paragraph-2-regular' : 'paragraph-1-regular'} style="secondary">
                    {trls[description]}
                </Text>
            }
            title={<Text typography={isMobile ? 'title-4-semibold' : 'title-3-semibold'}>{trls[title]}</Text>}
            buttonPrimary={
                isSubscribeButtonShowed ? (
                    <Button Element={Subscribe} mode="primary" style="accent">
                        {trls[TrlKeys.buttonText[count ? 'noActiveVacanciesSucces' : 'noActiveVacanciesDefault']]}
                    </Button>
                ) : undefined
            }
            buttonsStretched={false}
            image={`${staticHost}${PLACEHOLDER_IMAGE}`}
            imageWidth={393}
            imageHeight={219}
        />
    );
};

export default translation(NoVacancies);
