import { FC } from 'react';

import useExpEmployerVacancySearch from 'src/components/Employer/VacancySearch/useExpEmployerVacancySearch';

import Vacancies from 'src/components/Employer/VacanciesFilter/VacanciesFilter';

const VacanciesFilter: FC = () => {
    const { elementSpyRef } = useExpEmployerVacancySearch();
    return (
        <div ref={elementSpyRef}>
            <Vacancies />
        </div>
    );
};

export default VacanciesFilter;
