import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import PageLayout, { EmptyLayout } from 'src/app/layouts/PageLayout';
import paths from 'src/app/routePaths';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

export enum TileMode {
    Constructor = 'constructor',
    EditDescription = 'editDescription',
    View = 'view',
}

const TrlKeys = {
    [TileMode.Constructor]: 'employer.constructor.page.title',
    [TileMode.EditDescription]: 'employer.edit.simple.page.title',
    [TileMode.View]: 'employerview.meta.title',
};

interface EmployerPageTitleProps {
    mode: TileMode;
    employerName?: string | null;
}

const EmployerPageTitle: TranslatedComponent<EmployerPageTitleProps> = ({ trls, mode, children }) => {
    const isEmployerConstructor = useSelector(({ router }) => router.location.pathname === paths.employerConstructor);
    const { title, description } = useSelector((state) => state.pageMetaData);
    let pageTitle = trls[TrlKeys[isEmployerConstructor ? TileMode.Constructor : mode]];
    let pageDescription;
    if (mode === TileMode.View && !isEmployerConstructor) {
        pageTitle = title;
        pageDescription = description;
    }
    return (
        <PageLayout title={pageTitle} description={pageDescription} layout={EmptyLayout}>
            {children}
        </PageLayout>
    );
};

export default translation(EmployerPageTitle);
