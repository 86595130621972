import { FC, PropsWithChildren } from 'react';

import HSpacingContainer from 'bloko/blocks/hSpacing/HSpacingContainer';

import styles from './employer-badges.less';

const EmployerBadgesIconWrapper: FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className={styles.iconsContainer}>
            <HSpacingContainer base={1}>{children}</HSpacingContainer>
        </div>
    );
};

export default EmployerBadgesIconWrapper;
