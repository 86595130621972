import { FC, Fragment, memo, PropsWithChildren } from 'react';

interface SkeletonRepeatProps {
    count: number;
}

const SkeletonRepeat: FC<PropsWithChildren<SkeletonRepeatProps>> = ({ count, children }) => {
    if (!children) {
        return null;
    }

    return (
        <>
            {[...Array(count).keys()].map((_, i) => (
                <Fragment key={i}>{children}</Fragment>
            ))}
        </>
    );
};

export default memo(SkeletonRepeat);
