import { useCallback, PropsWithChildren, useRef, useEffect, useState } from 'react';

import { useElementShown } from '@hh.ru/analytics-js';
import employerPageVacancyTabTooltipCloseButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/employer_page/employer_page_vacancy_tab_tooltip_close_button_click';
import employerPageVacancyTabTooltipElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/employer_page/employer_page_vacancy_tab_tooltip_element_shown';
import { Tooltip } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Notices from 'Modules/Notices';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import { EmployerPageTab } from 'src/components/Employer/PageTabs/PageTabsContext';

import styles from './vacancies-tab-info-tip.less';

const INFOTIP_NAME = 'employer_view_vacancies_tab';

interface VacanciesTabInfoTipProps {
    currentTab: EmployerPageTab;
}

const TrlKeys = {
    info: 'employer.view.tabs.vacanceis.info',
};

const VacanciesTabInfoTip: TranslatedComponent<PropsWithChildren<VacanciesTabInfoTipProps>> = ({
    children,
    currentTab,
    trls,
}) => {
    const [isVisible, setIsVisible] = useState(true);
    const employerId = useSelector(({ employerInfo }) => Number(employerInfo.id));
    const showTip = useSelector(
        (state) => isVisible && currentTab !== EmployerPageTab.Vacancies && state.infoTip.name === INFOTIP_NAME
    );
    const [isAllowed, setIsAllowed] = useState(false);
    const activatorRef = useRef<HTMLSpanElement>(null);
    const ref = useElementShown(employerPageVacancyTabTooltipElementShown, { employerId });

    const onClose = useCallback(() => {
        setIsVisible(false);
        employerPageVacancyTabTooltipCloseButtonClick({ employerId });
        Notices.markAsViewed(INFOTIP_NAME);
    }, [employerId]);

    useEffect(() => {
        showTip &&
            // чтобы нормально позиционировалось без ssr
            setTimeout(() => {
                setIsAllowed(true);
            }, 3000);
    }, [showTip]);

    return (
        <>
            <span ref={activatorRef}>{children}</span>
            <Tooltip
                aria-label-close="close"
                activatorRef={activatorRef}
                closeByClickOutside={false}
                placement="bottom-center"
                guardDistanceToActivator={28}
                visible={isAllowed && showTip}
                showClose
                onClose={onClose}
            >
                <div className={styles.content} ref={ref}>
                    {trls[TrlKeys.info]}
                </div>
            </Tooltip>
        </>
    );
};

export default translation(VacanciesTabInfoTip);
