import { DefaultRootState } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import defaultError from 'src/components/Notifications/DefaultError';
import { AddNotification } from 'src/components/Notifications/Provider/types';
import { setLogoStatus, removeLogoUrl, Status } from 'src/models/employer/employerLogo';
import fetcher from 'src/utils/fetcher';

const DELETE_LOGO_URL = '/employer/edit/logo';

declare global {
    interface FetcherDeleteApi {
        [DELETE_LOGO_URL]: {
            queryParams: void;
            response: void;
        };
    }
}

export default (
        addNotification: AddNotification
    ): ThunkAction<Promise<unknown>, DefaultRootState, unknown, AnyAction> =>
    (dispatch) => {
        dispatch(setLogoStatus(Status.Fetching));
        return fetcher.delete(DELETE_LOGO_URL).then(
            () => {
                dispatch([removeLogoUrl(), setLogoStatus(Status.Success)] as unknown as AnyAction);
            },
            () => {
                dispatch(setLogoStatus(Status.Fail));
                addNotification(defaultError);
            }
        );
    };
